<template>
  <div class="fill-height backgroundMenu">
    <Lateral mensaje="holactm" />

    <!--<v-app-bar app>
         
    </v-app-bar>-->

    <!-- Sizes your content based upon application components -->
    <v-main>
      <div class="py-0 fill-height">
        <v-row class="fill-height" style="padding: 0px; margin: 0px">
          <v-col cols="12" id="contenido">
            <transition name="fade">
              <v-container>
                <router-view></router-view>
              </v-container>
            </transition>
          </v-col>
        </v-row>
      </div>
    </v-main>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import Lateral from "@/components/menu/Lateral.vue";

export default {
  components: {
    Lateral,
  },
  data() {
    return {
      sheet: false,
      comunidadInput: "",
      consulta: "",
      snackbarMensaje: false,
    };
  },
  methods: {
    ...mapActions(["cerrarSesion", "leerToken"]),
    abre() {
      console.log("abrio!");
    },
  },
  computed: {
    ...mapGetters(["estaActivo"]),
    ...mapState(["token", "usuarioDB"]),
  },
  created() {
    this.leerToken();
  },
};
</script>
<style>
.backgroundMenu {
  background: rgb(222, 229, 239);
  background: -moz-linear-gradient(
    0deg,
    rgba(222, 229, 239, 1) 0%,
    rgba(251, 252, 254, 1) 100%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(222, 229, 239, 1) 0%,
    rgba(251, 252, 254, 1) 100%
  );
  background: linear-gradient(
    0deg,
    rgba(222, 229, 239, 1) 0%,
    rgba(251, 252, 254, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#dee5ef",endColorstr="#fbfcfe",GradientType=1);
}
</style>

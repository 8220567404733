<template>
  <div>
    <v-app-bar
      app
      class="primary--text elevation-3"
      hide-on-scroll
      flat
      color="grisBarraApp"
      v-if="!showMenu()"
    >
      <v-app-bar-nav-icon @click="toggle = !toggle" color="primary"
        ><v-icon>mdi-dots-horizontal</v-icon></v-app-bar-nav-icon
      >

      <v-toolbar-title v-if="!toggle"
        ><b class="primary--text">Grupo Ingenieria</b></v-toolbar-title>
      <v-spacer v-else></v-spacer>
      <v-spacer></v-spacer>
      <v-toolbar-title class="m-0 p-0" v-if="!showMenu()">
        <v-list class="p-0 m-0" color="transparent" style="height: 63px">
          <v-list-item two-line class="py-0 px-0 m-0">
            <v-list-item-avatar size="40" class="elevation-2 p-0 mt-0 mb-0 transparent" @click="$router.push({ name: 'perfil' })">
              <img :src="foto()" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title
                style="font-size: 14px"
                class="indigo7--text font-weight-bold"
              >
              
                <!--{{ usuarioDB.data.nombre }}-->
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-toolbar-title>

      <v-icon class="primary--text" @click="cerrarSesion">mdi-logout</v-icon>
    </v-app-bar>

    <v-app-bar
      app
      class="indigo--text elevation-0"
      
      style="height: 63px; border: 0px solid #000"
      color="grisBarraApp"
      v-else
    >
    <v-progress-linear
        :active="true"
        absolute
        value="100"
        bottom
        color="primary lighten-5"
        
      ></v-progress-linear>
      
      <v-app-bar-nav-icon
        @click="toggle = !toggle"
        color="primary"
        style="margin-left: 0px"
      >
        <v-avatar color="primary">
          <v-icon class="verde--text"> mdi-dots-horizontal </v-icon>
        </v-avatar>
      </v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-toolbar-title class="m-0 p-0" v-if="showMenu()">
        <v-list class="p-0 m-0" color="transparent" style="height: 63px">
          <v-list-item
            two-line
            class="py-0 px-0 m-0"
            @click="$router.push({ name: 'perfil' })"
          >
            <v-list-item-avatar size="40" class="elevation-2 p-0 mt-0 mb-0">
              <img :src="foto()" />
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title
                style="font-size: 14px"
                class="indigo--text font-weight-bold"
              >
                {{ usuarioDB.data.nombre }}
              </v-list-item-title>
              <v-list-item-subtitle style="font-size: 12px" class="grey--text">
                {{ usuarioDB.data.cargo }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-toolbar-title>
      <v-avatar color="indigo lighten-5">
        <v-icon class="primary--text" dark @click="cerrarSesion">mdi-logout</v-icon>
      </v-avatar>
    </v-app-bar>
    <v-navigation-drawer
      class="primary mx-auto elevation-2"
      app
      v-model="toggle"
      :right="right"
      :permanent="permanent"
      dark
    >
    <div
      class="d-flex justify-center mb-2 mt-4"
    >
    
    <v-avatar size="100" style="background: #FFF;">
      <v-img
        :src="logonormal"
        alt="John"
      ></v-img>
    </v-avatar>
    </div>
      <v-list dense nav class="p-0" style="border-right: 10px">
        <!--<v-list-item :class="miniVariant && 'p-0 m-0 elevation-5'">-->

        
        <v-divider style="border: 0px" class="pt-0"></v-divider>

        <!--Nuevo-->
        <v-list
          class="pl-2 pr-2 pt-0 pb-0"
          v-for="item in items"
          :key="item.name"
          v-model="item.active"
        >
          <v-subheader :key="item.sub" class="white--text font-weight-bold">
            {{ item.sub }}
          </v-subheader>
          <div v-for="itemChildren in item.children">
            <!--<v-list-item-content>
              <v-list-item-title v-text="itemChildren.name"></v-list-item-title>
            </v-list-item-content>-->
            <v-list-group
              :key="itemChildren.name"
              v-model="itemChildren.active"
              :prepend-icon="itemChildren.icon"
              :to="itemChildren.link"
              no-action
              :group="itemChildren.grupo"
              active-class="verde--text"
              v-if="length(itemChildren.children) > 0"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title
                    class="font-weight-normal"
                    v-text="itemChildren.name"
                    active-class="verde--text"
                  ></v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                v-for="subItem in itemChildren.children"
                :key="subItem.name"
                active-class="verde--text"
                @click.prevent
                @click.stop
                :to="subItem.link"
                class="text-decoration-none"
              >
                <v-list-item-content>
                  <v-list-item-title
                    class="font-weight-normal"
                    v-text="subItem.name"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
            <v-list-item
              :key="`${itemChildren.name}`"
              active-class="verde--text"
              :to="itemChildren.link"
              class="text-decoration-none"
              v-else-if="itemChildren.link"
            >
              <v-list-item-icon>
                <v-icon v-text="itemChildren.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  class="font-weight-normal"
                  v-text="itemChildren.name"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list>

        <!--Nuevo-->

        <!--<v-list class="p-2 pt-8">
          <v-list-group
            v-for="item in items"
            :key="item.name"
            v-model="item.active"
            :prepend-icon="item.icon"
            :to="item.link"
            no-action
            :group="item.grupo"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title
                  class="font-weight-normal"
                  v-text="item.name"
                ></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="subItem in item.children"
              v-if="item.children.length > 0"
              :key="subItem.name"
              active-class="primary--text"
              @click.prevent
              @click.stop
              :to="subItem.link"
              class="text-decoration-none"
            >
              <v-list-item-content>
                <v-list-item-title
                  class="font-weight-normal"
                  v-text="subItem.name"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list>-->
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  name: "Lateral",
  props: {
    mensaje: String,
    tog: Boolean,
  },

  data() {
    return {
      drawer: true,
      nombrePersona: "",
      items: [],
      color: "purpura1",
      colors: ["primary", "blue", "success", "red", "teal"],
      right: false,
      permanent: false,
      miniVariant: this.mini(),
      expandOnHover: this.mini(),
      background: false,
      logo: require("@/assets/imagenes/logoblanco.png"),
      logonormal: require("@/assets/imagenes/logo5.png"),
      user: require("@/assets/imagenes/perfilDefault.png"),
      toggle: this.showMenu(),
    };
  },
  methods: {
    ...mapActions(["cerrarSesion"]),
    length(items) {
      if (items) {
        return items.length;
      } else {
        return 0;
      }
    },
    foto() {
      return this.usuarioDB.data.foto && this.usuarioDB.data.foto != ""
        ? process.env.VUE_APP_BASE_URL + "/users/verFoto/" + this.usuarioDB.data.foto
        : require("@/assets/imagenes/perfilDefault.png");
    },
    mini() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return false;
        case "sm":
          return false;
        case "md":
          return false;
        case "lg":
          return false;
        case "xl":
          return false;
      }
    },
    showMenu() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return false;
        case "sm":
          return false;
        case "md":
          return true;
        case "lg":
          return true;
        case "xl":
          return true;
      }
    },
    ndrawer() {
      if (this.showMenu()) {
        return "backgroundMenu fill-height";
      } else {
        return "backgroundMenu lighten-2 fill-height";
      }
    },
  },
  created() {
    //this.items = this.permisosMenu.items
    this.items = this.itemsMenu;
    this.nombrePersona = this.usuarioDB.data.nombre;
    this.role = this.usuarioDB.data.role;
  },
  computed: {
    ...mapState(["usuarioDB", "itemsMenu"]),
  },
  watch: {},
};
</script>

<style>
.backgroundMenu {
  background: rgb(50, 88, 244);
  background: -moz-linear-gradient(
    351deg,
    rgba(50, 88, 244, 1) 0%,
    rgba(31, 139, 247, 1) 100%
  );
  background: -webkit-linear-gradient(
    351deg,
    rgba(50, 88, 244, 1) 0%,
    rgba(31, 139, 247, 1) 100%
  );
  background: linear-gradient(
    351deg,
    rgba(50, 88, 244, 1) 0%,
    rgba(31, 139, 247, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#3258f4", endColorstr="#1f8bf7", GradientType=1);
}

.backgroundMenu2 {
  background: #FF0000;
}

.active {
  color: #00D7D2;
}
</style>
